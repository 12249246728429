import createClient from 'openapi-fetch'

import { API_ADMIN_HOST, API_HOST, DADATA_API_HOST, DADATA_API_KEY, PDF_API_HOST } from '../constants'

import type { paths } from './schema'
import { adminPaths, dadataPaths, pdfPaths } from './index'

export const client: ReturnType<typeof createClient<paths>> = createClient<paths>({
  baseUrl: API_HOST,
})

export const adminClient: ReturnType<typeof createClient<adminPaths>> = createClient<adminPaths>({
  baseUrl: API_ADMIN_HOST,
})

export const pdfClient: ReturnType<typeof createClient<pdfPaths>> = createClient<pdfPaths>({
  baseUrl: PDF_API_HOST,
})

export const dadataClient: ReturnType<typeof createClient<dadataPaths>> = createClient<dadataPaths>({
  baseUrl: DADATA_API_HOST,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Token ${DADATA_API_KEY}`,
  },
})
