import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

import { client } from '../schema/client'
import { getAuthHeader } from '../schema/getAuthHeader'

import { getUserInfo, loginUser } from './api'

import { useResetStore } from '../utils/useRestoreStore'
import { useRouter } from 'vue-router'
import { LoginData, LoginReturnData, ResetPasswordResponse } from './types'
import { components } from '../schema'

export const useAuthStore = defineStore(
  'auth',
  () => {
    const user = ref<components['schemas']['UserRead']>()
    const token = ref<string>()
    const isAuth = computed(() => !!user.value)
    const isLogOutAllowed = ref<boolean>(true)
    const isLogOutNeeded = ref<boolean>(false)
    const router = useRouter()

    const login = async (data: LoginData): Promise<LoginReturnData['error']> => {
      const response = await loginUser(data)
      token.value = response.token
      const userInfo = (await getUserInfo(token.value)).userInfo
      if (userInfo.role !== 'admin') {
        await logout()
        return {}
      }

      user.value = userInfo
      return response?.error
    }

    const logout = async (): Promise<void> => {
      await client.POST('/auth/logout', { headers: getAuthHeader() })
      token.value = undefined
      user.value = undefined

      const resetStore = useResetStore()
      if (resetStore.all) resetStore.all()

      router.push({ name: 'login' })
    }

    const resetPassword = async () // email: string,
    // new_password: string,
    // user_id: string,
    : Promise<ResetPasswordResponse> => {
      // return await adminClient.POST('/auth/reset-password', {
      //   headers: getAuthHeader(),
      //   body: { email, new_password, secret: sha1(user_id) },
      // })
    }

    const setIsLogOutAllowed = (value: boolean): void => {
      isLogOutAllowed.value = value
    }

    const setIsLogOutNeeded = (value: boolean): void => {
      isLogOutNeeded.value = value
    }

    const validateToken = async (): Promise<boolean> => {
      const { error, userInfo } = await getUserInfo(token.value)
      if (error) {
        token.value = undefined
        user.value = undefined
        return false
      }
      user.value = userInfo
      return true
    }

    return {
      user,
      token,
      isAuth,
      isLogOutAllowed,
      isLogOutNeeded,
      login,
      logout,
      setIsLogOutAllowed,
      setIsLogOutNeeded,
      validateToken,
      resetPassword,
    }
  },
  {
    persist: {
      key: 'admin-auth',
      paths: ['token'],
    },
  },
)
