import { formDataSerializer } from '../schema/bodySerializer'
import { client as apiClient } from '../schema/client'
import { LoginData, LoginReturnData } from './types'
import { components } from '../schema'

export const loginUser = async (data: LoginData): Promise<LoginReturnData> => {
  const response = await apiClient.POST('/auth/login', {
    body: data,
    bodySerializer: formDataSerializer,
  })

  return {
    token: response.data?.access_token,
    error: response.error?.detail as LoginReturnData['error'],
  }
}

export const getUserInfo = async (
  token: string | undefined,
): Promise<{ userInfo: components['schemas']['UserRead']; error: unknown }> => {
  const { data, error } = await apiClient.GET('/users/me', {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

  return { userInfo: data as components['schemas']['UserRead'], error }
}
