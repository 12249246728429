import type { BodySerializer } from 'openapi-fetch'

export const formDataSerializer: BodySerializer<FormData> = (body): FormData => {
  const formData = new FormData()

  for (const [key, value] of Object.entries(body as unknown as object)) {
    formData.append(key, value || '')
  }

  return formData
}
