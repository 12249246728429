export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'Главная',
  },
  routes: [
    {
      name: 'users',
      displayName: 'Сотрудники',
      meta: {
        icon: 'group',
      },
    },
    {
      name: 'hospitals',
      displayName: 'Мед. Учреждения',
      meta: {
        icon: 'folder_shared',
      },
    },
    {
      name: 'governments',
      displayName: 'Минздравы',
      meta: {
        icon: 'emergency',
      },
    },
    {
      name: 'settings',
      displayName: 'Настройки',
      meta: {
        icon: 'settings',
      },
    },
  ] as INavigationRoute[],
}
