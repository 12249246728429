import { type Pinia, type Store, getActivePinia } from 'pinia'

interface ExtendedPinia extends Pinia {
  _s: Map<string, Store>
}

export function useResetStore(): Record<string, () => void> {
  const pinia = getActivePinia() as ExtendedPinia

  if (!pinia) {
    throw new Error('There is no active Pinia instance')
  }

  const resetStore: Record<string, () => void> = {}

  pinia._s.forEach((store, name) => {
    resetStore[name] = (): void => store.$reset()
  })

  resetStore.all = (): void =>
    pinia._s.forEach((store): void => {
      try {
        store.$reset()
      } catch {
        // eslint-disable-next-line no-empty
      }
    })

  return resetStore
}
